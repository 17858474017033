<template>
    <div>
      <kendo-contextmenu
        :ref="'annotationContext'"
        :open="openContext"
        :select="selectContext">
        <li>Insert Annotation</li>
      </kendo-contextmenu>
      <kendo-window
        :ref="'annotationWindow'"
        :width="'500px'"
        :visible="false"
        :modal="true"
        style="display:none">
            <textarea id="comment" style="width: 100%" />
            <b-button-group class="action-buttons-nav">
            <b-button type="submit" variant="primary" size="sm" style="width: 80px" @click="addAnnotation">Add</b-button>
            &nbsp;
            <b-button type="submit" variant="primary" size="sm" style="width: 80px" @click="cancelWindow">Cancel</b-button>
        </b-button-group>
      </kendo-window>
    </div>
</template>

<script>

import mitt from 'mitt'
const emitter = mitt()

export default {
  methods: {
    openContext: function (e) {
      if (window.getSelection().toString().length === 0) {
        e.preventDefault()
      }
    },
    selectContext: function () {
      if (this.$store.state.currentFocus === 'Citation' | this.$store.state.currentFocus === 'Abstract' | this.$store.state.currentFocus === 'Full Text') {
        var windowWidget = this.$refs.annotationWindow.kendoWidget()
        this.highlightedtext = window.getSelection().toString()
        windowWidget
          .title('Specify ' + this.$store.state.currentFocus + ' annotation comment')
          .open()
          .center()
      }
    },
    cancelWindow: function () {
      var windowWidget = this.$refs.annotationWindow.kendoWidget()
      windowWidget.close()
    },
    addAnnotation: function () {
      emitter.emit('addAnnotation', this.$store.state.currentFocus, this.highlightedtext, document.getElementById('comment').value)
      document.getElementById('comment').value = ''
      var windowWidget = this.$refs.annotationWindow.kendoWidget()
      windowWidget.close()
    }
  }
}
</script>
